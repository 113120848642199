import { useState, useEffect, useRef, useCallback } from 'react';
import { getStorage, ref, getDownloadURL, deleteObject, uploadBytes } from "firebase/storage";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "./firebase";

const useAudioPlayer = (albumId, albumOwnerId, user, sharedWith, setTracks, setTrack) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [playingTrackId, setPlayingTrackId] = useState(null);
  const [playingSegmentIndex, setPlayingSegmentIndex] = useState(0);
  const [selectedTrackId, setSelectedTrackId] = useState(null);
  const [pausedTime, setPausedTime] = useState(0);
  const audioRef = useRef(new Audio());
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const currentTrackRef = useRef(null);
  const [isRecording, setIsRecording] = useState(false);
  const [recordingTrackId, setRecordingTrackId] = useState(null);
  const mediaRecorderRef = useRef(null);
  const chunksRef = useRef([]);
  const [recordingStartTime, setRecordingStartTime] = useState(null);
  const [remainingTime, setRemainingTime] = useState(2700); // 45 minutes
  const [browserNotSupported, setBrowserNotSupported] = useState(false);
  const [alertDialogOpen, setAlertDialogOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogContent, setDialogContent] = useState('');

  const userIdRef = useRef(user ? user.uid : null);

  useEffect(() => {
    userIdRef.current = user ? user.uid : null;
  }, [user]);

  // Handle audio events
  useEffect(() => {
    const audio = audioRef.current;

    const handleTimeUpdate = () => {
      setCurrentTime(audio.currentTime);
    };

    const handleDurationChange = () => {
      setDuration(audio.duration);
    };

    const handleAudioEnd = () => {
      if (currentTrackRef.current) {
        const nextSegmentIndex = playingSegmentIndex + 1;
        if (nextSegmentIndex < currentTrackRef.current.audioSegments.length) {
          // Play next segment
          playSegment(currentTrackRef.current, nextSegmentIndex);
        } else {
          // End of track
          setIsPlaying(false);
          setPlayingTrackId(null);
          setPlayingSegmentIndex(0);
          currentTrackRef.current = null;
        }
      } else {
        setIsPlaying(false);
      }
    };

    audio.addEventListener('timeupdate', handleTimeUpdate);
    audio.addEventListener('durationchange', handleDurationChange);
    audio.addEventListener('ended', handleAudioEnd);

    return () => {
      audio.removeEventListener('timeupdate', handleTimeUpdate);
      audio.removeEventListener('durationchange', handleDurationChange);
      audio.removeEventListener('ended', handleAudioEnd);
    };
  }, [playingSegmentIndex]);

  const playSegment = useCallback(async (track, segmentIndex) => {
    const segment = track.audioSegments[segmentIndex];
    const audioId = segment.id;

    const storage = getStorage();
    const m4aPath = `audio_segments/${segment.recordedBy}/${albumId}/segment-${audioId}.m4a`;

    try {
      const m4aRef = ref(storage, m4aPath);
      const audioUrl = await getDownloadURL(m4aRef);

      audioRef.current.src = audioUrl;
      await audioRef.current.play();
      setPlayingTrackId(audioId);
      setSelectedTrackId(audioId);
      setPlayingSegmentIndex(segmentIndex);
      setIsPlaying(true);
      currentTrackRef.current = track;
    } catch (error) {
      console.error("Error loading or playing audio file:", error);
    }
  }, [albumId]);

  const handlePause = useCallback(() => {
    audioRef.current.pause();
    setPausedTime(audioRef.current.currentTime);
    setIsPlaying(false);
  }, []);

  const handlePlayPause = useCallback(
    async (track, segmentIndex = 0) => {
      if (!track.audioSegments || track.audioSegments.length === 0) {
        console.error("No audio segments found for track:", track);
        return;
      }

      const segment = track.audioSegments[segmentIndex];

      if (isPlaying && segment.id === playingTrackId) {
        handlePause();
      } else if (!isPlaying && segment.id === playingTrackId) {
        // Resume playback from the paused position
        audioRef.current.currentTime = pausedTime;
        await audioRef.current.play();
        setIsPlaying(true);
      } else {
        // Start playing a new segment
        await playSegment(track, segmentIndex);
      }

      setSelectedTrackId(segment.id);
    },
    [isPlaying, playingTrackId, playSegment, pausedTime, handlePause]
  );

  const handleSeek = (time) => {
    audioRef.current.currentTime = time;
  };

  const handleDeleteSegment = useCallback(async (trackId, segmentId) => {
    try {
      // Fetch the album document
      const albumRef = doc(db, "albums", albumId);
      const albumSnapshot = await getDoc(albumRef);

      if (!albumSnapshot.exists()) {
        console.error("Album not found");
        return;
      }

      const albumData = albumSnapshot.data();
      const tracks = albumData.tracks || [];

      // Find the track
      const trackIndex = tracks.findIndex((track) => track.id === trackId);
      if (trackIndex === -1) {
        console.error("Track not found");
        return;
      }

      const track = tracks[trackIndex];

      // Find the segment
      const segmentIndex = track.audioSegments.findIndex((segment) => segment.id === segmentId);
      if (segmentIndex === -1) {
        console.error("Segment not found");
        return;
      }

      const segment = track.audioSegments[segmentIndex];

      // Check if the current user is authorized to delete the segment
      if (!user || user.uid !== segment.recordedBy) {
        console.error("User not authorized to delete this segment");
        return;
      }

      // Remove the segment from the track
      track.audioSegments.splice(segmentIndex, 1);

      // Update the track in the tracks array
      tracks[trackIndex] = track;

      // Update the album document
      await updateDoc(albumRef, {
        tracks: tracks,
        updatedAt: new Date(),
      });

      // Update the track state in Track.jsx
      if (setTrack) {
        setTrack(track);
      }

      // Delete the audio file from storage
      const storage = getStorage();
      const m4aPath = `audio_segments/${segment.recordedBy}/${albumId}/segment-${segmentId}.m4a`;
      await deleteObject(ref(storage, m4aPath));

      console.log("Segment deleted successfully");
    } catch (error) {
      console.error("Error deleting segment:", error);
    }
  }, [albumId, user, setTrack]);

  const startRecording = useCallback(
    async (trackId) => {
      console.log("startRecording called with trackId:", trackId);

      if (!trackId) {
        console.error("No trackId provided to startRecording");
        return;
      }

      if (!user || (user.uid !== albumOwnerId && !sharedWith.includes(user.uid))) {
        setDialogTitle('Permission Denied');
        setDialogContent("You don't have permission to record on this album.");
        setAlertDialogOpen(true);
        return;
      }

      try {
        console.log("Requesting microphone permission...");
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        console.log("Microphone permission granted");

        const mimeType = "audio/mp4";

        console.log("Using mimeType:", mimeType);

        mediaRecorderRef.current = new MediaRecorder(stream, {
          mimeType
        });
        chunksRef.current = [];

        mediaRecorderRef.current.ondataavailable = (event) => {
          if (event.data.size > 0) {
            chunksRef.current.push(event.data);
          }
        };

        mediaRecorderRef.current.onstop = async () => {
          console.log("Recording stopped, processing audio...");
          const audioBlob = new Blob(chunksRef.current, { type: "audio/mp4" });

          console.log("User ID:", userIdRef.current);

          try {
            const timestamp = Date.now();
            const m4aFileName = `segment-${timestamp}.m4a`;

            const storage = getStorage();
            const m4aPath = `audio_segments/${userIdRef.current}/${albumId}/${m4aFileName}`;

            // Upload M4A file
            const m4aRef = ref(storage, m4aPath);
            await uploadBytes(m4aRef, audioBlob);

            const albumRef = doc(db, "albums", albumId);
            const albumSnapshot = await getDoc(albumRef);
            const albumData = albumSnapshot.data();
            const tracks = albumData.tracks || [];

            const trackIndex = tracks.findIndex(
              (track) => track.id === recordingTrackId
            );
            if (trackIndex !== -1) {
              const updatedTracks = [...tracks];
              const newAudioSegment = {
                id: timestamp.toString(),
                recordedBy: userIdRef.current,
                updatedAt: new Date(),
                createdAt: new Date(),
              };
              updatedTracks[trackIndex].audioSegments =
                updatedTracks[trackIndex].audioSegments || [];
              updatedTracks[trackIndex].audioSegments.push(newAudioSegment);
              updatedTracks[trackIndex].updatedAt = new Date();

              await updateDoc(albumRef, {
                tracks: updatedTracks,
                updatedAt: new Date(),
              });

              // After update, fetch the latest data to get the server-generated timestamps
              const updatedDoc = await getDoc(albumRef);
              if (updatedDoc.exists()) {
                const updatedTracks = updatedDoc.data().tracks;
                setTracks(updatedTracks);

                // Update the track state in Track.jsx
                const updatedTrack = updatedTracks.find(track => track.id === recordingTrackId);
                if (setTrack) {
                  setTrack(updatedTrack);
                }
              }

              console.log("Recording uploaded and database updated");
              // Resolve the new audio segment
              return newAudioSegment;
            }
          } catch (error) {
            console.error("Error uploading recording:", error);
          }
          return null;
        };

        mediaRecorderRef.current.start();
        setIsRecording(true);
        setRecordingTrackId(trackId);
        setRecordingStartTime(Date.now());
        setRemainingTime(2700); // 45 minutes
        console.log("Recording started for track:", trackId);
      } catch (error) {
        console.error("Error starting recording:", error);
        if (error.name === "NotAllowedError") {
          setDialogTitle('Microphone Access Denied');
          setDialogContent('Microphone access was denied. Please check your browser settings and ensure that you\'ve granted permission for this site to use your microphone.');
          setAlertDialogOpen(true);
        } else if (error.name === "NotFoundError") {
          setDialogTitle('Microphone Not Found');
          setDialogContent('No microphone was found. Please ensure that a microphone is connected and try again.');
          setAlertDialogOpen(true);
        } else if (error.name === "NotSupportedError") {
          setBrowserNotSupported(true);
        } else {
          setDialogTitle('Error');
          setDialogContent(`An error occurred while trying to start recording: ${error.message}`);
          setAlertDialogOpen(true);
        }
      }
    },
    [albumOwnerId, sharedWith, user, setTrack]
  );

  const stopRecording = useCallback(async () => {
    if (mediaRecorderRef.current && isRecording) {
      return new Promise((resolve) => {
        let recordingProcessed = false; // Flag to prevent duplicate processing

        mediaRecorderRef.current.onstop = async () => {
          if (recordingProcessed) return;
          recordingProcessed = true;

          setIsRecording(false);
          setRecordingTrackId(null);
          setRecordingStartTime(null);
          setRemainingTime(2700); // 45 minutes

          const audioBlob = new Blob(chunksRef.current, { type: "audio/mp4" });
          const timestamp = Date.now();
          const m4aFileName = `segment-${timestamp}.m4a`;

          const storage = getStorage();
          const m4aPath = `audio_segments/${userIdRef.current}/${albumId}/${m4aFileName}`;

          try {
            // Upload M4A file
            const m4aRef = ref(storage, m4aPath);
            await uploadBytes(m4aRef, audioBlob);

            const newAudioSegment = {
              id: timestamp.toString(),
              recordedBy: userIdRef.current,
              updatedAt: new Date(),
              createdAt: new Date(),
            };

            const albumRef = doc(db, "albums", albumId);
            const albumSnapshot = await getDoc(albumRef);
            const albumData = albumSnapshot.data();
            const tracks = albumData.tracks || [];

            const trackIndex = tracks.findIndex(
              (track) => track.id === recordingTrackId
            );
            if (trackIndex !== -1) {
              const updatedTracks = [...tracks];
              updatedTracks[trackIndex].audioSegments =
                updatedTracks[trackIndex].audioSegments || [];
              updatedTracks[trackIndex].audioSegments.push(newAudioSegment);
              updatedTracks[trackIndex].updatedAt = new Date();

              await updateDoc(albumRef, {
                tracks: updatedTracks,
                updatedAt: new Date(),
              });

              // Re-fetch the updated album data
              const updatedDoc = await getDoc(albumRef);
              if (updatedDoc.exists()) {
                const updatedTracks = updatedDoc.data().tracks;
                if (setTracks) {
                  setTracks(updatedTracks);
                }

                // Update the track state in Track.jsx
                const updatedTrack = updatedTracks.find(track => track.id === recordingTrackId);
                if (setTrack) {
                  setTrack(updatedTrack);
                }
              }

              console.log("Recording uploaded and database updated");
              resolve(newAudioSegment);
            } else {
              resolve(null);
            }
          } catch (error) {
            console.error("Error uploading recording:", error);
            resolve(null);
          }
        };

        mediaRecorderRef.current.stop();
      });
    }
  }, [isRecording, albumId, recordingTrackId, setTracks, setTrack]);

  useEffect(() => {
    let intervalId;
    if (isRecording && recordingStartTime) {
      intervalId = setInterval(() => {
        const elapsedTime = Math.floor((Date.now() - recordingStartTime) / 1000);
        const remaining = Math.max(2700 - elapsedTime, 0); // 45 minutes
        setRemainingTime(remaining);

        if (remaining === 0) {
          stopRecording();
        }
      }, 1000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [isRecording, recordingStartTime, stopRecording]);

  return {
    // Playback
    isPlaying,
    playingTrackId,
    playingSegmentIndex,
    selectedTrackId,
    currentTime,
    duration,
    handlePause,
    handlePlayPause,
    handleSeek,
    audioRef,

    // Recording
    isRecording,
    startRecording,
    stopRecording,
    recordingTrackId,
    remainingTime,

    // Segment Management
    handleDeleteSegment,

    // Dialog Management
    browserNotSupported,
    alertDialogOpen,
    setAlertDialogOpen,
    dialogTitle,
    setDialogTitle,
    dialogContent,
    setDialogContent,
  };
};

export default useAudioPlayer;
